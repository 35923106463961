/* eslint-disable sonarjs/no-identical-functions */
export interface TeamBasicModel {
  teamId: string
  teamName: string
  teamImage132?: string
}

export interface TeamModel {
  teamId: string
  teamName: string
  teamDomain: string
  teamImage132?: string
  isEnterpriseInstall: boolean
  enterpriseName: string
  enterpriseId: string
  botUserId: string
  scopes: string[]
  expectedScopes: string[]
  missingScopes: boolean
  status: TeamStatusModel
  plan: TeamPlanModel
  features: TeamFeaturesModel
  createdAtUtc: string
  updatedAtUtc: string
  canManageTopics: boolean
  canAccessUserEmails: boolean
  canReadAndWriteIm: boolean
}

export interface TeamFeaturesModel {
  noDutyWords: string[]
  cleanDutyGroupsOnDutyChange: boolean
  whenLastUserGroupMemberIsRemoved: 'disableGroup' | 'leaveLastMemberIn'
}

export interface TeamFeaturesModelInput {
  noDutyWords?: string[]
  cleanDutyGroupsOnDutyChange?: boolean
  whenLastUserGroupMemberIsRemoved: 'disableGroup' | 'leaveLastMemberIn'
}

export interface TeamStatusModel {
  lastUpdateUtc: string
  rotations: number
  users: number
}

export interface TeamPlanModel {
  code: string
  paidPlan: boolean
  maxRotationsLimit: number
  maxUsersLimit: number
  lastChangeUtc: string
  subscriptionId: string
  customerId: string
  subscriptionOwners: string[]
  lastPaymentFailed: boolean
  paymentAttemptCount: number
  pendingCancellation: boolean
  endDateUtc: string
  rotationsAreLimited: boolean
  usersAreLimited: boolean
  upgradeBlocked: boolean
  purchasedQuantity: number
}

export class TeamPlanModelType implements TeamPlanModel {
  code: string
  paidPlan: boolean
  maxRotationsLimit: number
  maxUsersLimit: number
  lastChangeUtc: string
  subscriptionId: string
  customerId: string
  subscriptionOwners: string[]
  lastPaymentFailed: boolean
  pendingCancellation: boolean
  endDateUtc: string
  rotationsAreLimited: boolean
  usersAreLimited: boolean
  paymentAttemptCount: number
  upgradeBlocked: boolean
  purchasedQuantity: number

  constructor(plan: TeamPlanModel) {
    this.code = plan.code
    this.paidPlan = plan.paidPlan
    this.maxRotationsLimit = plan.maxRotationsLimit
    this.maxUsersLimit = plan.maxUsersLimit
    this.lastChangeUtc = plan.lastChangeUtc
    this.subscriptionId = plan.subscriptionId
    this.customerId = plan.customerId
    this.subscriptionOwners = plan.subscriptionOwners
    this.lastPaymentFailed = plan.lastPaymentFailed
    this.pendingCancellation = plan.pendingCancellation
    this.endDateUtc = plan.endDateUtc
    this.rotationsAreLimited = plan.rotationsAreLimited
    this.usersAreLimited = plan.usersAreLimited
    this.paymentAttemptCount = plan.paymentAttemptCount
    this.upgradeBlocked = plan.upgradeBlocked
    this.purchasedQuantity = plan.purchasedQuantity
  }

  get isFreePlan(): boolean {
    return !this.paidPlan
  }

  isAboveLimit(currentUsers: number, currentRotations: number): boolean {
    if (this.usersAreLimited && this.maxUsersLimit < currentUsers) {
      return true
    }
    return !!(this.rotationsAreLimited && this.maxRotationsLimit < currentRotations)
  }

  isSubscriptionOwner(userId: string | undefined): boolean {
    if (!userId) return false
    return this.subscriptionOwners.includes(userId)
  }
}

export class TeamModelType implements TeamModel {
  teamId: string
  teamName: string
  teamDomain: string
  teamImage132?: string
  isEnterpriseInstall: boolean
  enterpriseName: string
  enterpriseId: string
  botUserId: string
  scopes: string[]
  expectedScopes: string[]
  missingScopes: boolean
  status: TeamStatusModel
  plan: TeamPlanModelType
  features: TeamFeaturesModel
  createdAtUtc: string
  updatedAtUtc: string
  canManageTopics: boolean
  canAccessUserEmails: boolean
  canReadAndWriteIm: boolean

  constructor(team: TeamModel) {
    this.teamId = team.teamId
    this.teamName = team.teamName
    this.teamDomain = team.teamDomain
    this.teamImage132 = team.teamImage132
    this.isEnterpriseInstall = team.isEnterpriseInstall
    this.enterpriseName = team.enterpriseName
    this.enterpriseId = team.enterpriseId
    this.botUserId = team.botUserId
    this.scopes = team.scopes
    this.expectedScopes = team.expectedScopes
    this.missingScopes = team.missingScopes
    this.status = team.status
    this.plan = new TeamPlanModelType(team.plan)
    this.features = team.features
    this.createdAtUtc = team.createdAtUtc
    this.updatedAtUtc = team.updatedAtUtc
    this.canManageTopics = team.canManageTopics
    this.canAccessUserEmails = team.canAccessUserEmails
    this.canReadAndWriteIm = team.canReadAndWriteIm
  }

  get createdAtUtcDate(): Date {
    return new Date(this.createdAtUtc)
  }

  get updatedAtUtcDate(): Date {
    return new Date(this.updatedAtUtc)
  }
}

export interface TeamStatusHistoryModel {
  id: string
  teamId: string
  createdAtUtc: string
  rotations: TeamStatusHistoryRotationModel[]
  numberOfRotations: number
  numberOfUsers: number
  billableUsers: number
  changeReason: TeamStatusChangeReason
}

export interface TeamStatusHistoryRotationModel {
  rotationId: string
  rotationName: string
  numberOfDirectUsers: number
  numberOfUserGroups: number
  numberOfUsersInUserGroups: number
  totalNumberOfUsers: number
  members: RotationMembersModel
}

export type TeamStatusChangeReason =
  | 'rotationCreated'
  | 'rotationDeleted'
  | 'userListChanged'
  | 'userGroupCompositionChanged'
  | 'billingUpdate'
  | 'rotationEnabled'
  | 'rotationDisabled'

export interface TeamStatusHistoryInputModel {
  changeReason: string
  fromUtc: string
  toUtc: string
  reloadToken: number
}

export interface UserModel {
  userId: string
  slackUserId: string
  slackTeamId: string
  slackHandle: string
  firstName: string
  lastName: string
  displayName: string
  email: string
  image48: string
  image192: string
  isSlackAdmin: boolean
  isPlaceholder: boolean
}

export type UserRole = 'TeamOwner' | 'Admin' | 'User'

export class UserRoles {
  public static get USER(): UserRole {
    return 'User'
  }

  public static get ADMIN(): UserRole {
    return 'Admin'
  }

  public static get TEAM_OWNER(): UserRole {
    return 'TeamOwner'
  }
}

export type RotationMode = 'auto' | 'manual' | 'external'
export type ScheduleExternalPartner = 'none' | 'pagerDuty' | 'opsGenie'

export interface RotationPermissionsModel {
  canAccess: boolean
  canEdit: boolean
}

export interface RotationOptionsModel {
  randomizePlan: boolean
  avoidRepetitionsOnRandomPlan: boolean
  notificationsOptions: RotationNotificationOptionsModel
  businessHoursOptions: RotationBusinessHoursOptionsModel
  externalCalendarsOptions: RotationExternalCalendarsOptionsModel
  rotationMentionOptions: RotationMentionOptionsModel
}

export interface RotationExternalCalendarsOptionsModel {
  considerOutOfOfficeForDutyCalculation: string
  sizeLimitForOutOfOfficeDutyCalculation: number
}

export interface RotationNotificationOptionsModel {
  notifyBeforeShiftStart: boolean
  notifyBeforeShiftStartAlsoForBusinessHours: boolean
  notifyBeforeShiftStartAmount: number
  notifyBeforeShiftStartScale: string
  notifyBeforeShiftEnd: boolean
  notifyBeforeShiftEndAlsoForBusinessHours: boolean
  notifyBeforeShiftEndAmount: number
  notifyBeforeShiftEndScale: string
}

export interface RotationBusinessHoursOptionsModel {
  enableSupportForBusinessHours: boolean
  considerScheduleSkipsAsOffTime: boolean
  skipOnOffNotificationsDuringSequentialBusinessHours: boolean
  businessHoursTimeZone: string
  businessHours: TimeSlotModel[]
  isInBusinessHours: boolean
  currentBusinessHours: TimeSlotModel
  nextBusinessHour: TimeSlotModel
}

export interface RotationMentionOptionsModel {
  minutesToWaitBeforeNotifyingTheNextUserOnDuty: number
  roundRobinBetweenUsersOnDuty: boolean
  forceAcknowledgementOrNotifyAgain: boolean
  defaultExpirationInMinutes: number
}

export class RotationOptionsModelType implements RotationOptionsModel {
  randomizePlan: boolean
  avoidRepetitionsOnRandomPlan: boolean
  businessHoursOptions: RotationBusinessHoursOptionsModel
  notificationsOptions: RotationNotificationOptionsModel
  externalCalendarsOptions: RotationExternalCalendarsOptionsModel
  rotationMentionOptions: RotationMentionOptionsModel

  constructor(options: RotationOptionsModel) {
    this.randomizePlan = options.randomizePlan
    this.avoidRepetitionsOnRandomPlan = options.avoidRepetitionsOnRandomPlan
    this.businessHoursOptions = options.businessHoursOptions
    this.notificationsOptions = options.notificationsOptions
    this.externalCalendarsOptions = options.externalCalendarsOptions
    this.rotationMentionOptions = options.rotationMentionOptions
  }
}

export interface TimeSlotModel {
  beginLocalTime: string
  endLocalTime: string
  beginUtc: string
  endUtc: string
  beginTimeAtTimeZone: string
  endTimeAtTimeZone: string
}

export interface RotationModel {
  id: string
  name: string
  code: string
  enabled: boolean
  public: boolean
  createdAtUtc: string
  updatedAtUtc: string
  description: string
  channels: string[]
  owners: string[]
  channelNames: string[]
  members: RotationMembersModel
  plan: RotationPlanModel
  hasActiveDuty: boolean
  activeDuties: RotationDutyModel[]
  nextOnDuty: RotationDutyModel[]
  hasNextDuty: boolean
  dutySize: number
  mode: RotationMode
  scheduleMode: string
  workingDayRotationSchedule: WorkingDayRotationScheduleModel
  dayOfWeekRotationSchedule: DayOfWeekRotationSchedule
  dayOfMonthRotationSchedule: DayOfMonthRotationScheduleModel
  dailyRotationSchedule: DailyRotationScheduleModel
  nthWeekdayOfMonthRotationSchedule: NthWeekdayOfMonthRotationScheduleModel
  daysOfWeekListRotationSchedule: DaysOfWeekListScheduleModel
  manualSchedule: ManualScheduleModel
  externalSchedule: ExternalScheduleModel
  enableTopic: boolean
  topicChannels: string[]
  topicTemplate: string
  templates: RotationTemplatesModel | undefined
  enableNoDutyWords: boolean
  noDutyWords: string[]
  options: RotationOptionsModel
  hasOnDutyUserGroup: boolean
  onDutyUserGroupId: string
  callerPermissions: RotationPermissionsModel
}

export class RotationModelType implements RotationModel {
  id: string
  name: string
  code: string
  enabled: boolean
  public: boolean
  createdAtUtc: string
  updatedAtUtc: string
  description: string
  channels: string[]
  owners: string[]
  channelNames: string[]
  members: RotationMembersModelType
  plan: RotationPlanModel
  hasActiveDuty: boolean
  activeDuties: RotationDutyModel[]
  nextOnDuty: RotationDutyModel[]
  hasNextDuty: boolean
  dutySize: number
  mode: RotationMode
  scheduleMode: string
  workingDayRotationSchedule: WorkingDayRotationScheduleModel
  dayOfWeekRotationSchedule: DayOfWeekRotationSchedule
  dayOfMonthRotationSchedule: DayOfMonthRotationScheduleModel
  dailyRotationSchedule: DailyRotationScheduleModel
  nthWeekdayOfMonthRotationSchedule: NthWeekdayOfMonthRotationScheduleModel
  daysOfWeekListRotationSchedule: DaysOfWeekListScheduleModel
  manualSchedule: ManualScheduleModel
  externalSchedule: ExternalScheduleModel
  enableTopic: boolean
  topicChannels: string[]
  topicTemplate: string
  templates: RotationTemplatesModel | undefined
  enableNoDutyWords: boolean
  noDutyWords: string[]
  options: RotationOptionsModelType
  hasOnDutyUserGroup: boolean
  onDutyUserGroupId: string
  callerPermissions: RotationPermissionsModel

  constructor(rotation: RotationModel) {
    this.id = rotation.id
    this.name = rotation.name
    this.code = rotation.code
    this.enabled = rotation.enabled
    this.public = rotation.public
    this.createdAtUtc = rotation.createdAtUtc
    this.updatedAtUtc = rotation.updatedAtUtc
    this.description = rotation.description
    this.channels = rotation.channels
    this.owners = rotation.owners
    this.channelNames = rotation.channelNames
    this.members = new RotationMembersModelType(rotation)
    this.plan = rotation.plan
    this.hasActiveDuty = rotation.hasActiveDuty
    this.activeDuties = rotation.activeDuties
    this.nextOnDuty = rotation.nextOnDuty
    this.hasNextDuty = rotation.hasNextDuty
    this.dutySize = rotation.dutySize
    this.mode = rotation.mode
    this.scheduleMode = rotation.scheduleMode
    this.workingDayRotationSchedule = rotation.workingDayRotationSchedule
    this.dayOfWeekRotationSchedule = rotation.dayOfWeekRotationSchedule
    this.dayOfMonthRotationSchedule = rotation.dayOfMonthRotationSchedule
    this.dailyRotationSchedule = rotation.dailyRotationSchedule
    this.nthWeekdayOfMonthRotationSchedule = rotation.nthWeekdayOfMonthRotationSchedule
    this.daysOfWeekListRotationSchedule = rotation.daysOfWeekListRotationSchedule
    this.manualSchedule = rotation.manualSchedule
    this.externalSchedule = rotation.externalSchedule
    this.enableTopic = rotation.enableTopic
    this.topicChannels = rotation.topicChannels
    this.topicTemplate = rotation.topicTemplate
    this.templates = rotation.templates
    this.enableNoDutyWords = rotation.enableNoDutyWords
    this.noDutyWords = rotation.noDutyWords
    this.options = new RotationOptionsModelType(rotation.options)
    this.hasOnDutyUserGroup = rotation.hasOnDutyUserGroup
    this.onDutyUserGroupId = rotation.onDutyUserGroupId
    this.callerPermissions = rotation.callerPermissions
  }

  canAccess(): boolean {
    return this.callerPermissions.canAccess
  }

  canEdit(): boolean {
    return this.callerPermissions.canEdit
  }

  isOwner(userId: string | undefined): boolean {
    if (userId == undefined) return false
    return this.owners.includes(userId)
  }

  isMember(userId: string | undefined): boolean {
    if (userId == undefined) return false
    return this.members.allUsers().some((x) => x.userId === userId)
  }

  isAuto = (): boolean => this.mode === 'auto'
  isManual = (): boolean => this.mode === 'manual'
  isAutoOrManual = (): boolean => this.isAuto() || this.isManual()
  isExternallyManaged = (): boolean => this.mode === 'external'

  isPagerDutyManaged(): boolean {
    if (this.isExternallyManaged() == false) return false
    return this.externalSchedule.externalPartner === 'pagerDuty'
  }

  isOpsGenieManaged(): boolean {
    if (this.isExternallyManaged() == false) return false
    return this.externalSchedule.externalPartner === 'opsGenie'
  }

  get externalPartnerName(): string {
    return this.externalSchedule.externalPartner
  }

  isRandomized = (): boolean => this.options.randomizePlan

  hasCode = (): boolean => this.code !== '' && this.code != undefined

  get baseSchedule(): BaseRotationScheduleModelType {
    return new BaseRotationScheduleModelType(this)
  }
}

export class BaseRotationScheduleModelType implements BaseRotationScheduleModel {
  constructor(rotation: RotationModel | undefined) {
    if (!rotation) return

    if (rotation.mode === 'manual') {
      this.initializeAsManualRotationSchedule()
      return
    }

    if (rotation.dayOfWeekRotationSchedule) {
      this.initializeAsDayOfWeekRotationSchedule(rotation.dayOfWeekRotationSchedule)
    } else if (rotation.dayOfMonthRotationSchedule) {
      this.initializeAsDayOfMonthRotationSchedule(rotation.dayOfMonthRotationSchedule)
    } else if (rotation.dailyRotationSchedule) {
      this.initializeAsDailyRotationSchedule(rotation.dailyRotationSchedule)
    } else if (rotation.workingDayRotationSchedule) {
      this.initializeAsWorkingDayRotationSchedule(rotation.workingDayRotationSchedule)
    } else if (rotation.daysOfWeekListRotationSchedule) {
      this.initializeAsDaysOfWeekListRotationSchedule(
        rotation.daysOfWeekListRotationSchedule
      )
    } else if (rotation.nthWeekdayOfMonthRotationSchedule) {
      this.initializeAsNthWeekdayOfMonthRotationSchedule(
        rotation.nthWeekdayOfMonthRotationSchedule
      )
    } else if (rotation.externalSchedule) {
      this.initializeAsExternalRotationSchedule(rotation.externalSchedule)
    } else this.initializeAsManualRotationSchedule()
  }

  initializeAsDayOfMonthRotationSchedule(schedule: DayOfMonthRotationScheduleModel) {
    this.startingTimeAtTimeZone = schedule.startingTimeAtTimeZone
    this.timeZone = schedule.timeZone
    this.countryCodes = schedule.countryCodes
    this.period = schedule.period
    this.nextEventsUtc = schedule.nextEventsUtc
  }

  initializeAsDayOfWeekRotationSchedule(schedule: DayOfWeekRotationSchedule) {
    this.startingTimeAtTimeZone = schedule.startingTimeAtTimeZone
    this.timeZone = schedule.timeZone
    this.countryCodes = schedule.countryCodes
    this.period = schedule.period
    this.nextEventsUtc = schedule.nextEventsUtc
  }

  initializeAsDaysOfWeekListRotationSchedule(schedule: DaysOfWeekListScheduleModel) {
    this.startingTimeAtTimeZone = schedule.startingTimeAtTimeZone
    this.timeZone = schedule.timeZone
    this.countryCodes = schedule.countryCodes
    this.period = schedule.period
    this.nextEventsUtc = schedule.nextEventsUtc
  }

  initializeAsNthWeekdayOfMonthRotationSchedule(
    schedule: NthWeekdayOfMonthRotationScheduleModel
  ) {
    this.startingTimeAtTimeZone = schedule.startingTimeAtTimeZone
    this.timeZone = schedule.timeZone
    this.countryCodes = schedule.countryCodes
    this.period = schedule.period
    this.nextEventsUtc = schedule.nextEventsUtc
  }

  initializeAsWorkingDayRotationSchedule(schedule: WorkingDayRotationScheduleModel) {
    this.startingTimeAtTimeZone = schedule.startingTimeAtTimeZone
    this.timeZone = schedule.timeZone
    this.countryCodes = schedule.countryCodes
    this.period = schedule.period
    this.nextEventsUtc = schedule.nextEventsUtc
  }

  initializeAsDailyRotationSchedule(schedule: DailyRotationScheduleModel) {
    this.startingTimeAtTimeZone = schedule.startingTimeAtTimeZone
    this.timeZone = schedule.timeZone
    this.countryCodes = schedule.countryCodes
    this.period = schedule.period
    this.nextEventsUtc = schedule.nextEventsUtc
  }

  initializeAsManualRotationSchedule() {
    this.startingTimeAtTimeZone = ''
    this.timeZone = ''
    this.countryCodes = []
    this.period = 1
    this.nextEventsUtc = []
  }

  initializeAsExternalRotationSchedule(schedule: ExternalScheduleModel) {
    this.startingTimeAtTimeZone = ''
    this.timeZone = schedule.timeZone
    this.countryCodes = []
    this.period = 1
    this.nextEventsUtc = []
    this.externalPartner = schedule.externalPartner
  }

  startingTimeAtTimeZone!: string
  timeZone!: string
  countryCodes!: string[]
  period!: number
  nextEventsUtc!: string[]
  externalPartner?: ScheduleExternalPartner | undefined
}

export interface CreateRotationModel {
  name: string
  description: string
  channels: string[]
  owners: string[]
  userIds: string[]
  userGroupIds: string[]
}

export interface EditRotationModel {
  enabled?: boolean
  enableTopic?: boolean
  topicChannels?: string[]
  topicTemplate?: string
  enableNoDutyWords?: boolean
  noDutyWords?: string[]
  options?: RotationOptionsModel
}

export interface RotationMembersModel {
  rotationUsers: RotationMembersUserModel[]
  userGroups: RotationMemberUserGroupsModel[]
}

export interface RotationMembersUserModel {
  userId: string
  sequenceNumber: number
}

export interface RotationMembersUserModelViewType {
  userId: string
  sequenceNumber: number
  userGroupId?: string
}

export class RotationMembersModelType implements RotationMembersModel {
  constructor(rotation: RotationModel | undefined) {
    if (!rotation) return

    this.rotationUsers = rotation.members.rotationUsers
    this.userGroups = rotation.members.userGroups
  }

  rotationUsers!: RotationMembersUserModel[]
  userGroups!: RotationMemberUserGroupsModel[]

  allUsers(): RotationMembersUserModelViewType[] {
    const result: RotationMembersUserModelViewType[] = []

    this.userGroups.forEach((userGroup: RotationMemberUserGroupsModel) => {
      userGroup.rotationUsers.forEach((user) => {
        if (result.find((x) => x.userId === user.userId) == undefined) {
          result.push({
            sequenceNumber: user.sequenceNumber,
            userId: user.userId,
            userGroupId: userGroup.userGroupId
          })
        }
      })
    })

    this.rotationUsers.forEach((user: RotationMembersUserModel) => {
      if (result.find((x) => x.userId === user.userId) == undefined) {
        result.push({
          sequenceNumber: user.sequenceNumber,
          userId: user.userId
        })
      }
    })

    return result.sort((a, b) => a.sequenceNumber - b.sequenceNumber)
  }
}

export interface RotationMemberUserGroupsModel {
  userGroupId: string
  rotationUsers: RotationMembersUserModel[]
}

export interface RotationUserModel {
  userId: string
  userGroupId: string
  sequenceNumber: number
  userGroup: string
  picture: string
  position: number
  name: string
}

export interface RotationDutyModel {
  onDutyUntilUtc: string
  user: string
}

export interface WorkingDayRotationScheduleModel extends BaseRotationScheduleModel {}

export interface DayOfWeekRotationSchedule extends BaseRotationScheduleModel {
  dayOfWeek: string
  skipNationalHolidays: boolean
}

export interface NthWeekdayOfMonthRotationScheduleModel
  extends BaseRotationScheduleModel {
  dayOfWeek: string
  weeksInMonth: number[]
  skipNationalHolidays: boolean
}

export interface DayOfMonthRotationScheduleModel extends BaseRotationScheduleModel {
  dayOfMonth: number
  skipNationalHolidays: boolean
  skipWeekends: boolean
}

export interface DailyRotationScheduleModel extends BaseRotationScheduleModel {
  dailyTimes: string[]
  skipNationalHolidays: boolean
  skipWeekends: boolean
}

export interface DaysOfWeekListScheduleModel extends BaseRotationScheduleModel {
  daysOfWeek: string[]
  skipNationalHolidays: boolean
}

export interface ManualScheduleModel extends BaseRotationScheduleModel {}

export interface ExternalScheduleModel extends BaseRotationScheduleModel {
  externalPartner: ScheduleExternalPartner
}

export interface BaseRotationScheduleModel {
  startingTimeAtTimeZone: string
  timeZone: string
  countryCodes: string[]
  period: number
  nextEventsUtc: string[]
}

export interface RotationPlanModel {
  items: RotationPlanItemModel[]
}

export interface RotationPlanItemModel {
  userId: string
  userGroupId: string
  turn: string
}

export interface UserDashboardModel {
  rotationsOwned: string[]
  rotationsWhereIsMember: string[]
}

export interface DutyPlanModel {
  user: string
  turn: number
  onDutySince: string
  onDutySinceIsDutyShift: boolean
  onDutySinceIsBusinessHoursShift: boolean
  onDutyUntil: string
  onDutyUntilIsDutyShift: boolean
  onDutyUntilIsBusinessHoursShift: boolean
  rotationId: string
  rotationName: string
  isManual: boolean
  timeZone: string
  isUtc: boolean
  outOfOfficeFromUtc: string
  outOfOfficeUntilUtc: string
  willBeOutOfOffice: boolean
  replacingUserDueToOoo: boolean
  replacingUsers: DutyPlanReplacementModel[]
}

export interface DutyPlanReplacementModel {
  userId: string
  outOfOfficeFromUtc: string
  outOfOfficeUntilUtc: string
}

export interface DutyHistoryModel {
  user: string
  turn: number
  dutyId: string
  onDutySinceUtc: string
  onDutyUntilUtc: string
  rotationId: string
  reasonForDutyChange: string
}
export interface SetUsersOnDutyInputModel {
  userIds: string[]
}

export interface ResponseError {
  errors: Errors
  type: string
  title: string
  status: number
  detail: string
  instance: string
}

export interface Errors {
  [key: string]: string[]
}

export interface Unit {}

export interface SearchUsersInput {
  teamId: string
}

export interface SearchRotationsInput {
  name: string | undefined
  userId: string | undefined
  channelId: string | undefined
  userGroupId: string | undefined
  membershipMode: RotationsSearchMode
}

export interface PlanModel {
  code: string
  icon: string
  maxRotationsLimit: number
  maxUsersLimit: number
  costPerSeat: number
  productKey: string
  priceLookupKey: string
  billingPeriod: string
  quantityRequired: boolean
  minimumQuantity: number
  paidPlan: boolean
}

export class PlanModelType implements PlanModel {
  constructor(plan: PlanModel | undefined) {
    if (!plan) return

    this.code = plan.code
    this.icon = plan.icon
    this.maxRotationsLimit = plan.maxRotationsLimit
    this.maxUsersLimit = plan.maxUsersLimit
    this.costPerSeat = plan.costPerSeat
    this.productKey = plan.productKey
    this.priceLookupKey = plan.priceLookupKey
    this.billingPeriod = plan.billingPeriod
    this.quantityRequired = plan.quantityRequired
    this.minimumQuantity = plan.minimumQuantity
    this.paidPlan = plan.paidPlan
  }

  code!: string
  icon!: string

  maxRotationsLimit!: number
  maxUsersLimit!: number

  costPerSeat!: number

  productKey!: string
  priceLookupKey!: string
  billingPeriod!: string
  quantityRequired: boolean = false
  minimumQuantity!: number
  paidPlan: boolean

  maxRotationsLimitLabel(): string {
    return this.maxRotationsLimit === -1 ? 'Unlimited' : this.maxRotationsLimit.toString()
  }

  maxUsersLimitLabel(): string | undefined {
    if (this.quantityRequired) return undefined
    return this.maxUsersLimit === -1 ? 'Unlimited' : this.maxUsersLimit.toString()
  }

  costPerSeatLabel(): string {
    return this.costPerSeat === 0 ? 'Free' : `${this.costPerSeat.toString()} EUR`
  }

  costPerSeatLabelWithPeriod(): string {
    const period = this.billingPeriod === 'M' ? 'month' : 'year'
    return this.costPerSeat === 0
      ? 'Free'
      : `${this.costPerSeat.toString()} EUR / user / ${period}`
  }
}

export type RotationsSearchMode = 'all' | 'owner' | 'member'

export interface RotationTemplatesModel {
  userOnDutyTemplate: string | undefined
  userIncomingDutyTemplate: string | undefined
  userOffDutyTemplate: string | undefined
  userIncomingOffDutyTemplate: string | undefined
  channelOnDutyTemplate: string | undefined
  channelOffDutyTemplate: string | undefined
  channelBusinessHoursOnDutyTemplate: string | undefined
  channelBusinessHoursOffDutyTemplate: string | undefined
  enableUserOnDutyTemplate: boolean
  enableUserIncomingDutyTemplate: boolean
  enableUserOffDutyTemplate: boolean
  enableUserIncomingOffDutyTemplate: boolean
  enableChannelOnDutyTemplate: boolean
  enableChannelOffDutyTemplate: boolean
  enableChannelBusinessHoursOnDutyTemplate: boolean
  enableChannelBusinessHoursOffDutyTemplate: boolean
}

export interface RotationTemplatesInput {
  userOnDutyTemplate: string | undefined
  userIncomingDutyTemplate: string | undefined
  userOffDutyTemplate: string | undefined
  userIncomingOffDutyTemplate: string | undefined
  channelOnDutyTemplate: string | undefined
  channelOffDutyTemplate: string | undefined
  channelBusinessHoursOnDutyTemplate: string | undefined
  channelBusinessHoursOffDutyTemplate: string | undefined
  enableUserOnDutyTemplate: boolean
  enableUserIncomingDutyTemplate: boolean
  enableUserOffDutyTemplate: boolean
  enableUserIncomingOffDutyTemplate: boolean
  enableChannelOnDutyTemplate: boolean
  enableChannelOffDutyTemplate: boolean
  enableChannelBusinessHoursOnDutyTemplate: boolean
  enableChannelBusinessHoursOffDutyTemplate: boolean
}

export interface RotationTemplatesTestInput {
  userOnDutyTemplate: string | undefined
  userIncomingDutyTemplate: string | undefined
  userOffDutyTemplate: string | undefined
  userIncomingOffDutyTemplate: string | undefined
  channelOnDutyTemplate: string | undefined
  channelOffDutyTemplate: string | undefined
  channelBusinessHoursOnDutyTemplate: string | undefined
  channelBusinessHoursOffDutyTemplate: string | undefined
  topicTemplate: string | undefined
}

export interface RotationMessagesModel {
  userOnDutyMessage: string | undefined
  userIncomingDutyMessage: string | undefined
  userOffDutyMessage: string | undefined
  userIncomingOffDutyMessage: string | undefined
  channelOnDutyMessage: string | undefined
  channelOffDutyMessage: string | undefined
  channelBusinessHoursOnDutyMessage: string | undefined
  channelBusinessHoursOffDutyMessage: string | undefined
  topicMessage: string | undefined
}

export interface CreateCheckoutSessionInputItem {
  priceLookupKey: string
  quantity: number
}

export interface CreateCheckoutSessionInput {
  mode: string
  successUrlFragment: string
  cancelUrlFragment: string
  items: CreateCheckoutSessionInputItem[]
}

export interface StripeSessionModel {
  sessionUrl: string
}

export interface CreateBillingPortalSessionInput {
  returnUrl: string
}

export interface StripeInvoiceModel {
  total: number
  subtotal: number
  tax: number
  totalExcludingTax: number
  periodStart: string
  periodEnd: string
  currency: string
  amountOff: number
  percentageOff: number
  itemDescription: string
  itemQuantity: number
  itemAmount: number
  paid: boolean
  status: string
  invoicePdf: string
  invoiceUrl: string
}

export interface ResultContainerModel<T> {
  result: T
  exists: boolean
}

export interface RotationWebHookData {
  teamId: string
  rotationId: string
  webhookPath: string
  accessToken: string
}
